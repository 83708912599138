import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { User } from '../../../data/interfaces/user.interface';
import * as fromStore from '../../index';
import { AppState } from '../../index';

@Injectable({
    providedIn: 'root'
})
export class UserStoreFacadeService {
    constructor(private store: Store<AppState>) {}

    getUsers$(): Observable<User[]> {
        return this.store.select(fromStore.getUserEntities).pipe(
            filter(users => {
                return users !== undefined && Object.keys(users).length > 0;
            }),
            first(),
            map(users => {
                return Object.values(users);
            })
        );
    }

    getUser$(userId: number): Observable<User> {
        return this.getUsers$().pipe(
            first(),
            map(x => x.find(u => u.id == userId))
        );
    }
}
